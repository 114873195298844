
.skeleton-table {
    display: flex;
    flex-direction: column;
    gap: 10px;
    width: 85%;
  }
  
  .skeleton-row {
    display: flex;
    gap: 8px;
  }
  
  .skeleton-cell {
    flex: 1;
    height: 45px;
    background-color: #e0e0e0;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
  }
  
  .skeleton-cell::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -150px;
    height: 100%;
    width: 150px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
    animation: shimmer 1.5s infinite;
  }

  .skeleton-card {
    flex: 1;
    height: 120px;
    background-color: #e0e0e0;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
  }
  
  .skeleton-card::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -150px;
    height: 100%;
    width: 150px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
    animation: shimmer 1.5s infinite;
  }

  .skeleton-search {
    display: flex;
    gap: 8px;
    width: 50%;
    margin-bottom: 30px;
  }

  .skeleton-search-bar{
    flex: 1;
    height: 50px;
    background-color: #e0e0e0;
    border-radius: 4px;
    position: relative;
    overflow: hidden;
  }

  .skeleton-search::before {
    content: '';
    display: block;
    position: absolute;
    top: 0;
    left: -150px;
    height: 100%;
    width: 150px;
    background: linear-gradient(90deg, rgba(255, 255, 255, 0), rgba(255, 255, 255, 0.3), rgba(255, 255, 255, 0));
    animation: shimmer 1.5s infinite;
  }
  
  @keyframes shimmer {
    0% {
      left: -150px;
    }
    100% {
      left: 100%;
    }
  }
  
  