body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /* background-color: #f5f5f5; */
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

table tbody tr:nth-child(even){
  background-color: #ebeaf2 !important;
}

table tbody tr:nth-child(even):hover{
  background-color: #cdcae4 !important;
}

table tbody tr:nth-child(odd):hover{
  background-color: #e8f1eea6;
}
  

.row-container{
  margin-left: 300px;
}

.iti {
  display: block !important;
}

.iti__flag-container{
  margin-bottom: 0px !important;
}

.shimmer-wrapper {
  position: relative;
  overflow: hidden;
  background-color: #f6f7f8;
}

.shimmer {
  width: 100%;
  height: 100%;
  background: linear-gradient(90deg, #f6f7f8 25%, #eaeaea 50%, #f6f7f8 75%);
  background-size: 200% 100%;
  animation: shimmer 1.5s infinite;
}

@keyframes shimmer {
  0% {
    background-position: -200% 0;
  }
  100% {
    background-position: 200% 0;
  }
}

.orderlist{
  display: flex;
  align-items: center;
  gap: 2rem;
}

.orderlist .filter-container{
  width: 150px;
}

.orderlist .filter-container select{
  font-size: 16px;
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.catloader{
  background: white;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
}

.catloader img{
  width: 50%;
}


input{
  font-size: 20px;
}

.form-container {
  display: flex;
  justify-content: space-between; /* Distribute space between the items */
  flex-wrap: wrap; /* Allow items to wrap on smaller screens */
}

.form-group {
  flex: 0 0 48%; /* Each item takes 48% of the container width */
  box-sizing: border-box; /* Ensure padding and border are included in the width */
  margin-bottom: 1rem; /* Space below each input for better spacing */
}

/* Basic styling for Login */
.login {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  background: light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4)) ;
}

.loginform {
  width: 400px;
  padding: 20px;
  border-radius: 8px;
  border: 1px solid #ccc;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  text-align: center;
  background: white;
}

.error{
  color: red;
  font-size: 18px;
}

.loginform input{
  width: 90%;
  padding: 15px;
  margin-bottom: 10px;
  border-radius: 10px;
  background-color: #f4f4f4;
  border: none;
}

input:valid{
  background-color: light-dark(rgb(232, 240, 254), rgba(70, 90, 126, 0.4)) !important;
}


.loginform button{
  padding: 15px;
  width: 98%;
  background-color: #c6bdee;
  cursor: pointer;
  font-size: 18px;
  border: none;
  font-weight: 600;
  color: #825464;
  border-radius: 20px;
}

.loginform button:hover{
  background-color: #ad9cf4;
}


/* Basic styling for the top navigation bar */
.navbar {
  height: 80px; 
  width: 100%;
  background-color: #fff; 
  position: fixed;
  top: 0;
  left: 0;
  z-index: 99; 
}

.nav-element{
  display: flex;
  justify-content: space-between;
}

.nav-element .profile{
  color: #222;
  font-size: 20px;
  padding: 20px 30px;
  position: relative;
  margin-right: 10px;
}

.nav-element .profile:hover{
  cursor: pointer;
}

.profile-option {
  display: none;
  position: absolute;
  top: 100%;
  right: 15px;
  background-color: #fff;
  padding: 10px 10px;
  width: 200px;
  line-height: 2;
  text-align: center;
  border: #D4C4EC 1px solid;
  z-index: 9;
  border-radius: 10px;
}

.nav-element .profile:hover .profile-option {
  display: block;
}

.profile-1:hover{
  color: #743cc9;
  background-color: #D4C4EC;
  width: 100%;
}

/* Basic styling for the left navigation bar */
.left-nav {
  height: 100%;
  width: 200px;
  position: fixed;
  top: 80px;
  left: 0;
  background-color: #fff;
  text-align: left;
  padding: 20px;
}

.left-nav .left-nav-link.active {
  background-color: #D4C4EC;
}

.left-nav a {
  font-size: 20px;
  display: block;
  padding: 15px 15px; 
  text-decoration: none;
  color: #222; 
  border-radius: 10px;
  margin-bottom: 5px;
}

.left-nav a:hover{
  background-color: #b79be1 !important;
}

.page_title{
  padding-top: 100px;
  display: flex;
  justify-content: space-between;
}

.add_btn{
  display: inline-block;
  text-decoration: none;
  padding: 15px 30px;
  margin-top: 30px;
  margin-bottom: 20px;
  margin-right: 100px;
  color: #222;
  background-color: #D4C4EC;
  font-size: 16px;
  border-radius: 10px;
  border: 1px solid #D4C4EC;
  font-weight: 600;
  text-align: center;
  cursor: pointer;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1); /* Shadow */
}

.add_btn:hover{
  background-color: #b79be1;
}
.edit_btn{
  display: inline-block;
  text-decoration: none;
  padding: 10px 20px;
  margin-right: 20px;
  color: #222;
  font-size: 16px;
  border-radius: 10px;
  border:none;
  text-align: center;
  cursor: pointer;
}

.reply_btn{
  display: inline-block;
  background-color: transparent;
  text-decoration: none;
  padding: 10px 20px;
  margin-right: 20px;
  color: #222;
  font-size: 16px;
  border-radius: 10px;
  border:none;
  text-align: center;
  cursor: pointer;
}

.edit_btn:hover{
  background-color: #b79be1;
}
.delete_btn{
  padding: 9px;
  background-color: transparent;
  font-size: 16px;
  border-radius: 10px;
  border: none;
  text-align: center;
  cursor: pointer;
}

.delete_btn:hover{
  background-color: #341a1f;
}

/* Basic styling for the Cards */

.card-grid {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
}

.card {
  border: 1px solid #D4C4EC;
  border-radius: 8px;
  padding: 20px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  color: #222;
  background-color: #D4C4EC;
  width: 70%;
}

.card h2 {
  margin-top: 0;
  margin-bottom: 5px;
}

.card p {
  margin-bottom: 0;
  font-size: 14px;
  font-weight: 500;
}

/* Basic styling for the Card table */

.tablecard-container {
  padding-top: 3%;
  display: flex;
  gap: 15px;
  margin-bottom: 10px;
  justify-content: space-between;
  width: 95%;
} 

.tablecard-container .tablecard {
  padding: 20px;
  color: #222;
  background-color: #fff;
  width: 80%;
  border: #D4C4EC 1px solid;
  border-top: purple 5px solid;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  border-radius: 10px;
}
.dash-orders td{
  text-align: center;
  padding: 8px;
}

.table-card {
  flex: 0 0 48%; 
  margin-bottom: 20px;
}

.table-card table {
  width: 100%;
  border-collapse: collapse;
}

.table-card table th,
.table-card table td {
  padding: 8px;
  text-align: center;
}

.table-card table th {
  background-color: #d4c4ec;
}
.table-card {
  flex: 0 0 calc(48% - 10px);
  margin-bottom: 20px; 
}

.table-card + .table-card {
  margin-left: 20px; 
}

/* Table */

.table-data {
  width: 100%;
  border-collapse: collapse;
}

.table-data th,
.table-data td {
  padding: 1px 10px;
  text-align: center;
  font-size: 14px;
  cursor: pointer;
}

.table-data th {
  padding: 10px;
  background-color: #d4c4ec;
}

.category_form{
  width: 80%;
  margin-bottom: 50px;
  /* margin: 0 auto; */
}

.category_form div {
  margin-bottom: 15px;
}

.category_form label {
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
}

.category_form input[type="text"],
.category_form input[type="file"] {
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
  background: #f4f4f4;
  border: none;
}

.category_form input[type="checkbox"] {
  margin-right: 5px;
}

.category_form button {
  background-color: #D4C4EC;
  color: #222;
  padding: 15px 25px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
}

.category_form button:hover {
  background-color: #b79be1;
}

.product_form{
  width: 90%;
  margin-bottom: 50px;
  /* margin: 0 auto; */
}

.product_form div {
  margin-bottom: 15px;
}

.product_form label {
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
}

.product_form input[type="text"],
.product_form input[type="number"],
.product_form textarea,
.product_form input[type="file"] {
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 15px;
  background: #f4f4f4;
  border: none;
}

.product_form input[type="checkbox"] {
  margin-right: 5px;
}

.product_form button {
  background-color: #D4C4EC;
  color: #222;
  padding: 15px 25px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
}

.product_form button:hover {
  background-color: #b79be1;
}

/* Style for select dropdown */
.product_form select {
  font-size: 16px;
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  margin-bottom: 15px;
}

/* Style for option elements within select dropdown */
.product_form select option {
  padding: 8px;
}

.customer_form{
  width: 80%;
  margin-bottom: 50px;
  /* margin: 0 auto; */
}

.customer_form label {
  margin-top: 15px;
}

.customer_form label {
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
}

.customer_form input[type="text"],
.customer_form input[type="email"],
.customer_form input[type="tel"],
.customer_form input[type="number"],
  .customer_form input[type="file"] {
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
}

.customer_form input[type="checkbox"] {
  margin-right: 5px;
}

.customer_form button {
  background-color: #D4C4EC;
  color: #222;
  padding: 15px 25px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
}

.customer_form button:hover {
  background-color: #b79be1;
}

/* Container for the invoice */
.invoice-container {
  max-width: 800px;
  margin-left: 25%;
  margin-top: 5%;
  padding: 40px;
  background-color: #fff;
  border: 1px solid #ccc;
  border-radius: 8px;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
}

/* Invoice header */
.invoice-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 20px;
}

.invoice-header h1 {
  font-size: 24px;
}

.invoice-header input[type="text"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 200px;
}

/* Invoice details */
.invoice-details {
  margin-bottom: 20px;
}

.invoice-details label {
  font-weight: bold;
}

.invoice-details input[type="text"],
.invoice-details textarea {
  width: 98%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  margin-bottom: 10px;
}

/* Invoice items table */
.invoice-items {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.invoice-items th, .invoice-items td {
  border: 1px solid #ccc;
  padding: 10px;
  text-align: left;
}

.invoice-items th {
  background-color: #f2f2f2;
}

/* Invoice totals */
.invoice-totals {
  display: flex;
  justify-content: flex-end;
  align-items: center;
  margin-top: 20px;
}

.invoice-totals label {
  margin-right: 10px;
}

.invoice-totals input[type="text"] {
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  width: 150px;
}

/* Invoice actions */
.invoice-actions {
  text-align: center;
  margin-top: 20px;
}

.invoice-actions button {
  padding: 10px 20px;
  background-color: #4CAF50;
  color: white;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.invoice-actions button:hover {
  background-color: #45a049;
}

/* Select box styling */
.invoice-details select {
  width: 100%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
  background-color: #fff;
  font-size: 16px;
  background-repeat: no-repeat;
  background-position: right 8px center;
  background-size: 16px;
}

/* Style for when select box is focused */
.invoice-details select:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

/* Style for input fields in the item table */
.invoice-items input[type="text"],
.invoice-items input[type="number"] {
  width: 90%;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 4px;
}

/* Adjust the style when input fields are focused */
.invoice-items input[type="text"]:focus,
.invoice-items input[type="number"]:focus {
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}
.invoice-container .add_item{
  padding: 10px;
  border-radius: 10px;
  background-color: #D4C4EC;
  border: 1px solid #D4C4EC;
  cursor: pointer;
  margin-bottom: 20px;
}
.invoice-details table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.invoice-details th,
.invoice-details td {
  border: 1px solid #ddd;
  padding: 8px;
}

.invoice-details th {
  background-color: #f2f2f2;
  text-align: left;
}
.invoice-details .address textarea {
  width: 90%;
}

.invoice-details .address textarea:focus {
  width: 100%;
  padding: 8px;
  box-sizing: border-box;
  outline: none;
  border-color: #007bff;
  box-shadow: 0 0 0 2px rgba(0, 123, 255, 0.25);
}

.fixed-column {
  position: sticky;
  z-index: 1; /* Ensure it's above other columns */
}
.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-top: 20px;
  margin-bottom: 30px;
}

.footer .pagination {
  margin-right: 100px;
  display: flex;
  align-items: center;
}

.footer .pagination span {
padding: 5px;
}


.footer .pagination button {
  margin-right: 5px;
  padding: 5px 10px;
  border: 1px solid #ccc;
  background-color: #f5f5f5;
  cursor: pointer;
}

.footer .pagination button:hover {
  background-color: #e0e0e0;
}

.footer .pagination button:disabled {
  cursor: not-allowed;
  opacity: 0.5;
}

.total_rows {
  font-weight: bold;
}

/* src/Navbar.css */
.tabs {
  margin-bottom: 20px;
}

.tabs button {
  margin-right: 10px;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background-color: white;
}

.tabs button.active {
  border-bottom: 5px solid #007bff;
  color: #000;
}

.image-upload-section input[type="file"] {
  padding: 15px;
  border-radius: 15px;
}

.image-upload-section button {
  background-color: #D4C4EC;
  color: #222;
  padding: 15px 25px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
}

.image-upload-section button:hover {
  background-color: #b79be1;
}

.order-container {
  width: 90%;
  margin-bottom: 50px;
 }

.accordion {
  border-radius: 4px;
}

.accordion-item {
  margin-bottom: 10px;
}

.accordion-header {
  background-color: #f1f1f1;
  padding: 10px;
  cursor: pointer;
}

.accordion-button {
  cursor: pointer;
  background: none;
  border: none;
  width: 100%;
  text-align: left;
  font-size: 18px;
  font-weight: 600;
  padding: 10px;
}

.accordion-content {
  background-color: white;
  padding: 10px;
}
.order-container input[type="text"],.order-container input[type="number"],.order-container input[type="tel"] {
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 15px;
  background: #f4f4f4;
  border: none;
}

.order-container textarea{
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 15px;
  background: #f4f4f4;
  border: none;
  font-size: 18px;
}

.product-table {
  width: 100%;
  border-collapse: collapse;
  margin-bottom: 20px;
}

.product-table th, .product-table td {
  border: 1px solid #ccc;
  padding: 8px;
  text-align: left;
}

.input-table {
  width: 100%;
  box-sizing: border-box;
}

.totals-label {
  text-align: right;
  padding-right: 10px;
}

.add-button {
  padding: 10px 20px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
  border-radius: 10px;
}

.order-container .order-button{
  display: flex;
  gap: 20px;
}

.submit-button {
  background-color: #D4C4EC;
  color: #222;
  padding: 15px 25px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
}

.invoice-button {
  background-color: #4CAF50; /* Green */
  color: white;
  border: none;
  padding: 15px 25px;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
}

.invoice-button:hover {
  background-color: #45a049;
}

.order-container .submit-button:hover {
  background-color: #b79be1;
}

.order-container input[type='date']{
  padding: 10px;
  width: 95%;
}


.order-container select {
  font-size: 16px;
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
}

.order-container .subtotal{
  cursor: not-allowed;
}

.order-container .totals-sum{
  cursor: not-allowed;
}

.search-results {
  position: absolute;
  background: white;
  border: 1px solid #ccc;
  max-height: 150px;
  overflow-y: auto;
  width: 200px;
  list-style: none;
  padding: 0;
  margin: 0;
}

.search-results li {
  padding: 8px;
  cursor: pointer;
}

.search-results li:hover {
  background-color: #f0f0f0;
}

.search-container{
  margin-bottom: 20px;
  display: flex;
  gap: 20px;
}

.search-container input[type="text"], .search-container input[type="date"] {
  width: 50%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
  margin-bottom: 15px;
  background: #f4f4f4;
  border: none;
}

.search-container button{
  height: 54px;
  width: 90px;
  cursor: pointer;
  background: #abd4e0;
  border: none;
  font-weight: 600;
  border-radius: 10px;
}

/* Modal Background */

.reply_modal{
width: 60% !important;
}

.reply_modal textarea{
  width: 100%;
  margin-bottom: 2rem;
}

.modal {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.5); /* Semi-transparent background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999;
}

/* Modal Content */
.modal-content {
  background-color: #fff;
  padding: 20px;
  border-radius: 8px;
  width: 400px;
  max-width: 90%;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
}

/* Heading */
.modal-content h3 {
  margin-top: 0;
  margin-bottom: 20px;
  font-size: 1.5rem;
  text-align: center;
}

/* Form Labels */
.modal-content label {
  display: block;
  margin-bottom: 5px;
  font-weight: bold;
}

/* Form Inputs */
.modal-content input,
.modal-content select {
  width: 100%;
  padding: 8px;
  margin-bottom: 15px;
  border: 1px solid #ccc;
  border-radius: 4px;
  box-sizing: border-box;
  font-size: 1rem;
}

/* Button Styling */
.modal-content button {
  padding: 10px 20px;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  margin-right: 10px;
}

.modal-content button:hover {
  background-color: #007bff;
  color: white;
}

.modal-content button:last-child {
  background-color: #6c757d;
}

.modal-content button:last-child:hover {
  background-color: #5a6268;
}

/* Close Button (optional, if you want a "close" X in the top right corner) */
.modal-content .close-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  background: none;
  border: none;
  font-size: 1.5rem;
  cursor: pointer;
}


.advertisement_form{
  width: 80%;
  margin-bottom: 50px;
  /* margin: 0 auto; */
}

.advertisement_form label {
  margin-top: 15px;
}

.advertisement_form label {
  display: block;
  margin-bottom: 10px;
  font-size: 18px;
}

.advertisement_form input[type="text"],
.advertisement_form input[type="file"] {
  width: 100%;
  padding: 15px;
  border: 1px solid #ccc;
  border-radius: 10px;
  box-sizing: border-box;
}

.advertisement_form input[type="checkbox"] {
  margin-right: 5px;
}

.advertisement_form button {
  background-color: #D4C4EC;
  color: #222;
  padding: 15px 25px;
  border: none;
  border-radius: 10px;
  cursor: pointer;
  font-size: 16px;
}

.advertisement_form button:hover {
  background-color: #b79be1;
}

/* For mobile responsiveness */
@media (max-width: 600px) {
  .modal-content {
    width: 100%;
    padding: 15px;
  }
}

.newsletter-table th,
.newsletter-table td{
  font-size:20px;
}